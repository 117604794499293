import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";
import ProfileNavbar, {
  entries,
  ProfileInfo,
  BreadCrumb,
  TabContainer,
} from "../Components/ProfileNavbar";
import UnAuthResult from "../Components/UnAuthResult";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import * as Cart from "../Contexts/CartContext";
import { Context } from "../AppContext";
import { errorHandler } from "../errors";
import OrderTable, { TableTypes } from "../Components/OrderTable";
import { navigate } from "gatsby";

const ENTRY_NAME = "periods";
const ENTRY = entries.find(e => e.name === ENTRY_NAME) || {};

function PeriodOrderListPage(props) {
  const { profile } = props;
  const [search, setSearch] = useState("");
  const [selectedEntry, setSelectedEntry] = useState(ENTRY.entries[0]);
  const [rwdSidebarOpen, setRwdSidebarOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        let resp = await Cart.Actions.fetchOrders({
          order_type: "period",
          no_page: true,
        });
        setOrders(resp);
      } catch (err) {
        errorHandler(err);
      }
      app.actions.setLoading(false);
    })();
  }, [app.actions, Cart.Actions]);

  return (
    <Wrapper rwdSidebarOpen={rwdSidebarOpen}>
      {!profile ? (
        <UnAuthResult />
      ) : (
        <>
          <div className="background-color"></div>
          <div className="center-content">
            <Ant.Row>
              <Ant.Col className="profile-sidebar" xl={5} lg={0} md={0}>
                <ProfileNavbar />
              </Ant.Col>
              <Ant.Col className="profile-tab-content" xl={19} lg={24} md={24}>
                <BreadCrumb entry={ENTRY} leafNode={selectedEntry} />
                <div className="greeting" style={{ marginBottom: 36 }}>
                  Hi ! {profile.name || profile.email}
                </div>
                {/* <div className="row">
                  <div style={{ flex: 1 }}/>
                  <Ant.Input.Search 
                    allowClear
                    onSearch={value => this.setState({ search: value })}
                    placeholder="搜尋訂單編號"
                    style={{ width: 300, marginBottom: 10 }}
                    />
                  </div> */}
                <TabContainer
                  entry={ENTRY}
                  leafNode={selectedEntry}
                  onTabClick={entry => setSelectedEntry(entry)}
                >
                  {/* <OrderList
                    type={FilterStatusTypes.PERIOD}
                    search={search}
                  /> */}

                  <TableWrapper>
                    <OrderTable
                      type={TableTypes.PERIOD}
                      orders={orders}
                      mounted={true}
                      onItemClick={order => {
                        app.actions.setLoading(true);
                        navigate(`/order?id=${order.id}`);
                      }}
                    />
                  </TableWrapper>
                </TabContainer>
              </Ant.Col>
              <Widget.ToggleDrawer
                onClick={() => setRwdSidebarOpen(prev => !prev)}
              >
                {!rwdSidebarOpen ? (
                  <>
                    <div
                      style={{
                        color: Theme.colors.textReverse,
                        fontSize: 13,
                      }}
                    >
                      會員
                    </div>
                    <div
                      style={{
                        color: Theme.colors.textReverse,
                        fontSize: 13,
                      }}
                    >
                      選單
                    </div>
                  </>
                ) : (
                  <>
                    <Icon.KeyboardArrowRight
                      size={20}
                      color={Theme.colors.textReverse}
                    />
                    <div
                      style={{
                        color: Theme.colors.textReverse,
                        fontSize: 13,
                      }}
                    >
                      收合
                    </div>
                  </>
                )}
              </Widget.ToggleDrawer>
            </Ant.Row>
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  @media screen and (max-width: ${Theme.breakpoints.sm}px) {
    padding: 20px 0px;
  }

  & > .background-color {
    // hack to fill the for the background color of profile-sidebar
    position: fixed;
    top: 0px;
    left: 0px;
    width: 22%;
    height: 100%;
    background-color: ${Theme.colors.eggShell};
    @media screen and (max-width: ${Theme.breakpoints.xl}px) {
      display: none;
    }
  }

  & > .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0 auto;

    .profile-sidebar {
      display: flex;
      flex-direction: column;
      height: 1250px;
      background-color: ${Theme.colors.eggShell};
      padding: ${Theme.rwdPadding.larger} ${Theme.rwdPadding.larger}
        ${Theme.rwdPadding.larger} 0;
      overflow-y: auto;
      @media screen and (max-width: 1440px) {
        // hotfix: fix strange layout when 1440
        padding: ${Theme.rwdPadding.larger} ${Theme.rwdPadding.medium} 0
          ${Theme.rwdPadding.medium};
      }
      @media screen and (max-width: ${Theme.breakpoints.xl}px) {
        position: fixed;
        height: auto;
        width: 500px;
        left: ${props => (props.rwdSidebarOpen ? "0px" : "-500px")};
        top: ${Theme.navbarHeightMore}px;
        bottom: 0;
        transition: left 200ms;
        z-index: 200;
      }

      @media screen and (max-width: ${Theme.breakpoints.xs}px) {
        width: 298px;
        left: ${props => (props.rwdSidebarOpen ? "0px" : "-298px")};
      }
    }
    .profile-tab-content {
      padding: ${Theme.rwdPadding.larger} 0 0 90px;
      @media screen and (max-width: 1440px) {
        padding: ${Theme.rwdPadding.larger} 90px 0 90px;
      }
      @media screen and (max-width: ${Theme.breakpoints.lg}px) {
        padding: 40px ${Theme.rwdPadding.outer};
      }
      & > .greeting {
        ${Theme.textTitleStyle};
      }
    }
  }
`;

const TableWrapper = styled.div`
  h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
  }
  @media screen and (max-width: ${Theme.breakpoints.sm}px) {
    padding: 20px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(PeriodOrderListPage);
